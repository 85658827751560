import * as React from 'react';

interface SectionHeaderProps {
  title: string;
  size?: 'small' | 'medium' | 'large';
}

const SectionHeaderComponent = ({ title, size }: SectionHeaderProps) => {
  const className = ['SectionHeader'];
  if (size) className.push(`SectionHeader--size-${size}`);

  return (
    <div className={className.join(' ')}>
      {title}
    </div>
  );
};

export default SectionHeaderComponent;
