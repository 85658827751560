import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from '@common/types/store';
import { formatSubmission } from '@modules/forms/selectors';
import { formatNotificationSettings } from '@modules/forms/reducers';
import Api from '@common/services/api';
import Spinner from '@common/components/spinner';
import * as ajax from '../../ajax';
import Submissions from '../submissions/submissions';

import '@modules/forms/components/submissions/submission.scss';

export default function ModeratorFormSubmissions(props: any) {
  const {
    match, selectedForm, data, setForm,
  } = props;
  const { url, path } = match;

  const [submissions, setSubmissions] = useState<any>([]);
  const [submissionResponse, setSubmissionResponse] = useState<any>(null);

  const [networks, setNetworks] = useState<any>(null);
  const organisationId = data?.organisation?.id;
  useEffect(() => {
    Api.get(
      `/v4/organisations/${organisationId}/networks?include_all=true`
    ).then((res: any) => {
      const rows = res.data.sort((a: any, b: any) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });
      setNetworks(rows);
    });
  }, [organisationId]);

  const submissionDetail = submissionResponse?.data;
  const submission = useMemo(() => {
    const sub = submissions.find((s: any) => s.id === submissionDetail?.id);
    return formatSubmission(sub, submissionDetail);
  }, [submissions, submissionDetail, formatSubmission]);

  const fns = useMemo(() => ({
    fetchSubmissions: async (
      nextCursor: string | null,
      filter: ajax.FetchSubmissionsFilters,
      limit = 10,
    ) => {
      const res = await ajax.fetchSubmissions(
        organisationId, nextCursor, filter, limit
      );
      setSubmissions(res.data);
      return res;
    },
    fetchSubmission: async (idForm: string, id: string) => {
      const res = await ajax.fetchSubmission(organisationId, idForm, id);
      setSubmissionResponse(res);
    },
    downloadSubmissions: (
      idForm: string,
      fileName: string,
      extension: 'csv' | 'xlsx'
    ) => {
      return ajax.downloadSubmissions(organisationId, idForm, fileName, extension);
    },
    updateSubmissionStatus: (
      idForm: string,
      submissionId: string,
      status: any
    ) => {
      ajax.updateSubmissionStatus(
        organisationId, idForm, submissionId, status
      ).then(setSubmissionResponse);

      const subs = submissions.map((sub: any) => {
        if (sub.id === submissionId) {
          return {
            ...sub,
            response_status: status
          };
        }
        return sub;
      });
      setSubmissions(subs);
    },
    deleteSubmission: async (
      idForm: string,
      submissionId: string
    ) => {
      await ajax.deleteSubmission(organisationId, idForm, submissionId);
      const subs = submissions.filter((s: any) => {
        return s.id !== submissionId;
      });
      setSubmissions(subs);
    },
  }), [setSubmissions, organisationId, setSubmissionResponse, submissions]);

  const submissionsPath = useMemo(() => {
    if (path.endsWith(':submissionId')) {
      const chunks = url.split('/');
      chunks.pop();
      return chunks.join('/');
    }
    return url;
  }, [url, path]);

  const setLoggedUserNotificationsEnabled = useNotificationsEnabler(setForm);

  const loaded = selectedForm && networks;

  if (loaded) {
    return (
      <Submissions
        {...props} {...fns} {...data}
        networks={networks}
        submissions={submissions}
        submission={submission}
        submissionMeta={submissionResponse?.meta}
        selectedForm={selectedForm}
        submissionsPath={submissionsPath}
        setLoggedUserNotificationsEnabled={setLoggedUserNotificationsEnabled}
      />
    );
  }
  return <Spinner className="FormModeratorSubmissions__Spinner" size="large" centered />;
}

function useNotificationsEnabler(setForm: (form: any) => void) {
  // this callback allows us to set the value for user forms notifications
  const userId = useSelector((state: StoreState) => state.loggedUser).user.id;
  const setEnabled = useCallback((enabled: boolean) => {
    setForm((prevForm: any) => {
      const updatedForm = formatNotificationSettings(prevForm, userId, enabled);
      return updatedForm;
    });
  }, [setForm, userId]);
  return setEnabled;
}
